<template>
  <div
    v-if="isVisible"
    class="fixed inset-0 isolate z-50 flex justify-center overflow-auto p-6"
  >
    <div
      class="fixed inset-0 -z-10 cursor-pointer bg-black/30 dark:bg-black/70"
      @click="hide"
    ></div>

    <div
      class="relative my-auto rounded-md border border-slate-200 bg-white dark:border-slate-600 dark:bg-slate-950"
      :class="[props.size]"
    >
      <header
        v-if="$slots.header"
        class="flex items-start gap-4 p-4 pb-2 text-xl font-medium"
      >
        <slot name="header" />

        <button
          v-if="props.closeButton"
          class="ml-auto text-xl leading-none"
          @click="hide"
        >
          <Icon name="tabler:x" />
        </button>
      </header>
      <button
        v-else-if="props.closeButton"
        class="absolute right-2 top-2 text-xl leading-none"
        @click="hide"
      >
        <Icon name="tabler:x" />
      </button>

      <div
        class="p-4"
        :class="{
          'pt-2': $slots.header,
          'pt-7': !$slots.header && props.closeButton,
        }"
      >
        <slot :hide="hide"></slot>
      </div>

      <footer
        v-if="$slots.footer"
        class="flex border-t border-t-slate-200 p-4 dark:border-t-slate-600"
      >
        <slot
          name="footer"
          v-bind="{ hide }"
        >
        </slot>
      </footer>
    </div>
  </div>
</template>

<script setup lang="ts">
import { hideAllPoppers } from "floating-vue"

const props = withDefaults(
  defineProps<{
    closeButton?: boolean
    size?: string
  }>(),
  {
    size: "w-[700px]",
    closeButton: true,
  }
)

defineExpose({
  show,
  hide,
})

const isVisible = defineModel<boolean>({ default: false })

function show() {
  isVisible.value = true
}

function hide() {
  isVisible.value = false
}

const isScrollLocked = useScrollLock(document.documentElement)
watchEffect(() => {
  isScrollLocked.value = isVisible.value
})

const route = useRoute()
watch(() => route.fullPath, hide)

// TODO move to tooltip plugin
watchEffect(() => {
  if (isVisible.value) {
    // tooltips don't hide in safari when modal was opened
    hideAllPoppers()
  }
})
</script>
